import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, TextInput } from 'flowbite-react';
import { AiOutlineLoading, AiOutlineDelete, AiOutlineDrag, AiOutlinePlus } from 'react-icons/ai';
import { MdDragIndicator } from "react-icons/md";
import { serverURL } from '../constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Topics = () => {

    const { state } = useLocation();
    const [processing, setProcessing] = useState(false);
    const [jsonData, setJsonData] = useState(state?.jsonData || {});
    const { mainTopic, type } = state || {};
    const navigate = useNavigate();

    useEffect(() => {

        if (!jsonData) {
            navigate("/create");
        }

    }, []);

    function redirectCreate() {
        navigate("/create");
    }

    const showToast = async (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }
    const addSubtopic = (topicIndex) => {
        const newJsonData = { ...jsonData };
        const newSubtopic = { title: "" };
        newJsonData[mainTopic][topicIndex].subtopics.push(newSubtopic);
        setJsonData(newJsonData);
    }

    const deleteSubtopic = (topicIndex, subtopicIndex) => {
        const newJsonData = { ...jsonData };
        newJsonData[mainTopic][topicIndex].subtopics.splice(subtopicIndex, 1);
        setJsonData(newJsonData);
    }

    const updateSubtopicTitle = (topicIndex, subtopicIndex, newTitle) => {
        const newJsonData = { ...jsonData };
        newJsonData[mainTopic][topicIndex].subtopics[subtopicIndex].title = newTitle;
        setJsonData(newJsonData);
    }

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newJsonData = { ...jsonData };
        const [reorderedItem] = newJsonData[mainTopic][result.source.droppableId].subtopics.splice(result.source.index, 1);
        newJsonData[mainTopic][result.destination.droppableId].subtopics.splice(result.destination.index, 0, reorderedItem);

        setJsonData(newJsonData);
    }

    function redirectCourse() {

        const mainTopicData = jsonData[mainTopic][0];

        const firstSubtopic = mainTopicData.subtopics[0];

        if (type === 'video & text course') {

            const query = `${firstSubtopic.title} ${mainTopic} in english`;
            sendVideo(query, firstSubtopic.title);
            setProcessing(true);

        } else {

            // const prompt = `Explain me in long as you can about this subtopic of ${mainTopic} with examples :- ${firstSubtopic.title}. Please Strictly Don't Give Additional Resources And Images.`;
            const promptImage = `Example of ${firstSubtopic.title} in ${mainTopic}`;
            setProcessing(true);
            sendPrompt(promptImage, mainTopic, firstSubtopic.title);

        }

    }

    async function sendPrompt(promptImage, mainTopic, subTopics) {
        const dataToSend = {
            mainTopic: mainTopic,
            subTopics: subTopics,
            documentIds: JSON.parse(sessionStorage.getItem('lastEmbedIdS') || '[]'),
            lastDocsURLs: JSON.parse(sessionStorage.getItem('lastDocsURLs') || '[]'),
        };
        try {
            const postURL = serverURL + '/api/generatebyids';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.text;
            const htmlContent = generatedText;

            try {
                const parsedJson = htmlContent;
                sendImage(parsedJson, promptImage);
            } catch (error) {
                sendPrompt(prompt, promptImage)
            }

        } catch (error) {
            sendPrompt(prompt, promptImage)
        }
    }

    async function sendImage(parsedJson, promptImage) {
        const dataToSend = {
            prompt: promptImage,
        };
        try {
            const postURL = 'https://aibuilderapi.kareai.io/api/image';
            const res = await axios.post(postURL, dataToSend);
            try {
                const generatedText = res.data.url;
                const urlreff = res.data.urls;
                sendData(generatedText, parsedJson, urlreff);
                setProcessing(false);
            } catch (error) {
                const generatedText = 'https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png';
                sendData(generatedText, parsedJson);
                setProcessing(false);
                // sendImage(parsedJson, promptImage)
            }

        } catch (error) {
            const generatedText = 'https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png';
            sendData(generatedText, parsedJson);
            setProcessing(false);
            // sendImage(parsedJson, promptImage)
        }
    }

    async function sendData(image, theory, urlreff) {
        jsonData[mainTopic][0].subtopics[0].theory = theory;
        jsonData[mainTopic][0].subtopics[0].image = image;
        jsonData[mainTopic][0].subtopics[0].urlreff = urlreff;

        const user = sessionStorage.getItem('uid');
        const content = JSON.stringify(jsonData);
        const postURL = serverURL + '/api/course';
        const lastDocsURLs = JSON.parse(sessionStorage.getItem('lastDocsURLs') || '[]');
        const lastEmbedIdS = JSON.parse(sessionStorage.getItem('lastEmbedIdS') || '[]');
        const response = await axios.post(postURL, { user, content, type, mainTopic, lastDocsURLs, lastEmbedIdS });

        if (response.data.success) {
            showToast(response.data.message);
            sessionStorage.setItem('courseId', response.data.courseId);
            sessionStorage.setItem('first', response.data.completed);
            sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
            navigate('/course', { state: { jsonData: jsonData, mainTopic: mainTopic.toUpperCase(), type: type.toLowerCase(), courseId: response.data.courseId, end: '' } });
        } else {
            sendData(image, theory, urlreff)
        }

    }

    async function sendDataVideo(image, theory) {
        jsonData[mainTopic][0].subtopics[0].theory = theory;
        jsonData[mainTopic][0].subtopics[0].youtube = image;

        const user = sessionStorage.getItem('uid');
        const content = JSON.stringify(jsonData);
        const postURL = serverURL + '/api/course';
        const response = await axios.post(postURL, { user, content, type, mainTopic });

        if (response.data.success) {
            showToast(response.data.message);
            sessionStorage.setItem('courseId', response.data.courseId);
            sessionStorage.setItem('first', response.data.completed);
            sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
            navigate('/course', { state: { jsonData: jsonData, mainTopic: mainTopic.toUpperCase(), type: type.toLowerCase(), courseId: response.data.courseId, end: '' } });
        } else {
            sendDataVideo(image, theory)
        }

    }

    async function sendVideo(query, subtopic) {
        const dataToSend = {
            prompt: query,
        };
        try {
            const postURL = serverURL + '/api/yt';
            const res = await axios.post(postURL, dataToSend);

            try {
                const generatedText = res.data.url;
                sendTranscript(generatedText, subtopic);
            } catch (error) {
                sendVideo(query, subtopic)
            }

        } catch (error) {
            sendVideo(query, subtopic)
        }
    }

    async function sendTranscript(url, subtopic) {
        const dataToSend = {
            prompt: url,
        };
        try {
            const postURL = serverURL + '/api/transcript';
            const res = await axios.post(postURL, dataToSend);

            try {
                const generatedText = res.data.url;
                const allText = generatedText.map(item => item.text);
                const concatenatedText = allText.join(' ');
                const prompt = `Summarize this theory in a teaching way and :- ${concatenatedText}.`;
                sendSummery(mainTopic, subtopic, url);
            } catch (error) {
                const prompt = `Explain me about this subtopic of ${mainTopic} with examples :- ${subtopic}. Please Strictly Don't Give Additional Resources And Images.`;
                sendSummery(mainTopic, subtopic, url);
            }

        } catch (error) {
            const prompt = `Explain me about this subtopic of ${mainTopic} with examples :- ${subtopic}. Please Strictly Don't Give Additional Resources And Images.`;
            sendSummery(mainTopic, subtopic, url);
        }
    }

    async function sendSummery(mainTopic, subTopic, url) {
        const dataToSend = {
            mainTopic:mainTopic,
            subTopic: subTopic,
            documentIds: JSON.parse(sessionStorage.getItem('lastEmbedIdS') || '[]'),
            lastDocsURLs: JSON.parse(sessionStorage.getItem('lastDocsURLs') || '[]'),
        };
        try {
            const postURL = serverURL + '/api/generatebyids';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.text;
            const htmlContent = generatedText;

            try {
                const parsedJson = htmlContent;
                setProcessing(false);
                sendDataVideo(url, parsedJson);
            } catch (error) {
                sendSummery(mainTopic, subTopic, url)
            }

        } catch (error) {
            sendSummery(mainTopic, subTopic, url)
        }
    }

    const renderTopicsAndSubtopics = (topics) => {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                {topics.map((topic, topicIndex) => (
                    <div key={topic.title}>
                        <h3 className='w-full text-white bg-black px-4 py-2 mt-8 mb-2 font-black text-lg dark:bg-white dark:text-black'>{topic.title}</h3>
                        <Droppable droppableId={topicIndex.toString()}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {topic.subtopics.map((subtopic, subtopicIndex) => (
                    <Draggable key={`${topicIndex}-${subtopicIndex}`} draggableId={`${topicIndex}-${subtopicIndex}`} index={subtopicIndex}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className='w-full border-black border bg-white px-4 py-2 mb-2 font-normal text-sm dark:text-white dark:border-white dark:bg-black flex items-center'
                        >
                          <span {...provided.dragHandleProps}><MdDragIndicator className="mr-2" /></span>
                          <TextInput
                            key={`${topicIndex}-${subtopicIndex}`}
                            value={subtopic.title}
                            onChange={(e) => updateSubtopicTitle(topicIndex, subtopicIndex, e.target.value)}
                            className="flex-grow"
                          />
                                                    <Button onClick={() => deleteSubtopic(topicIndex, subtopicIndex)} className="ml-2" style={{ backgroundImage: 'linear-gradient(147deg, #880caa, #008da6)'}}>
                                                        <AiOutlineDelete style={{ color: 'white' }}/>
                                                    </Button>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <Button onClick={() => addSubtopic(topicIndex)} className="mt-2" style={{ backgroundImage: 'linear-gradient(147deg, #880caa, #008da6)', color: 'white'}}>
                            <AiOutlinePlus className="mr-2" style={{ color: 'white' }} /> Add Themes
                        </Button>
                    </div>
                ))}
            </DragDropContext>
        );
    };


    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={true} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div>
                    <h1 style={{ textTransform: 'uppercase' }} className='text-4xl text-black font-black text-center dark:text-white pt-4' >{mainTopic}</h1>
                    <p className='text-center font-bold mt-2 text-base text-black dark:text-white'>List of topics and subtopics course will cover</p>
                    <div className='max-md:max-w-sm max-sm:max-w-xs max-w-lg m-auto py-10'>
                        
                        {jsonData && renderTopicsAndSubtopics(jsonData[mainTopic])}
                        <Button onClick={redirectCourse} isProcessing={processing} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} className='items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent mt-10 mb-2'>Generate Course</Button>
                        <Button onClick={redirectCreate} type="button" className='mb-6 items-center justify-center text-center border-black dark:border-white dark:bg-black dark:text-white bg-white text-[#530f66] font-bold rounded-none w-full enabled:hover:bg-white enabled:focus:bg-white enabled:focus:ring-transparent dark:enabled:hover:bg-black dark:enabled:focus:bg-black dark:enabled:focus:ring-transparent'>Go Back</Button>
                    </div>
                </div>
            </div>
            {/* <Footers className="sticky bottom-0 z-50" /> */}
        </div>
    );
};

export default Topics;