import React, { useState } from 'react';
import { Button } from 'flowbite-react';

const Quiz = ({ questions }) => {
  const [userAnswers, setUserAnswers] = useState(Array(questions.length).fill(null));
  const [showResults, setShowResults] = useState(false);

  const handleAnswer = (questionIndex, optionIndex) => {
    const newAnswers = [...userAnswers];
    newAnswers[questionIndex] = optionIndex;
    setUserAnswers(newAnswers);
  };

  const calculateScore = () => {
    return userAnswers.reduce((score, answer, index) => {
      return answer === questions[index].correctAnswer ? score + 1 : score;
    }, 0);
  };

  return (
    <div className="mt-5 mb-5">
      <h2 className="text-xl font-bold mb-4">Quiz</h2>
      {questions.map((question, qIndex) => (
        <div key={qIndex} className="mb-6">
          <p className="font-semibold mb-2">{qIndex + 1}. {question.question}</p>
          {question.options.map((option, oIndex) => (
            <div key={oIndex} className="flex items-center mb-2">
              <input
                type="radio"
                id={`q${qIndex}o${oIndex}`}
                name={`question${qIndex}`}
                checked={userAnswers[qIndex] === oIndex}
                onChange={() => handleAnswer(qIndex, oIndex)}
                className="mr-2"
              />
              <label htmlFor={`q${qIndex}o${oIndex}`}>{option}</label>
            </div>
          ))}
          {showResults && (
            <p className={`mt-2 ${userAnswers[qIndex] === question.correctAnswer ? 'text-green-600' : 'text-red-600'}`}>
              {userAnswers[qIndex] === question.correctAnswer ? 'Correct!' : `Incorrect. The correct answer is: ${question.options[question.correctAnswer]}`}
            </p>
          )}
        </div>
      ))}
      <Button onClick={() => setShowResults(true)} className="mt-4" style={{ backgroundColor: '#530f66' }}>
        Show Results
      </Button>
      {showResults && (
        <p className="mt-4 font-bold">Your score: {calculateScore()} out of {questions.length}</p>
      )}
    </div>
  );
};

export default Quiz;