import React, { useEffect, useState } from 'react';
import { Upload, FileUp, Loader2, X } from 'lucide-react';
import Header from '../components/header';
import { Button, Label, Radio } from 'flowbite-react';
import { AiOutlineLoading } from 'react-icons/ai';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { serverURL } from '../constants';

const Create = () => {
    const maxSubtopics = 5;
    const [formValues, setFormValues] = useState([{ sub: "" }]);
    const [processing, setProcessing] = useState(false);
    const [selectedValue, setSelectedValue] = useState('5');
    const [selectedType, setSelectedType] = useState('Text & Image Course');
    const [paidMember, setPaidMember] = useState(false);
    const [lableText, setLableText] = useState('For free member sub topics is limited to 5');
    const navigate = useNavigate();
    
    // Updated state for multiple files
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [analysisData, setAnalysisData] = useState({
        topic: '',
        subTopics: [],
        summary: ''
    });

    const handleFileSelect = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const newErrors = {};
        const validFiles = selectedFiles.filter(file => {
            if (file.type !== 'application/pdf') {
                newErrors[file.name] = 'Please select a valid PDF file';
                return false;
            }
            if (file.size > 10 * 1024 * 1024) {
                newErrors[file.name] = 'File size should be less than 10MB';
                return false;
            }
            return true;
        });

        setErrors(newErrors);
        if (validFiles.length > 0) {
            setFiles(prevFiles => [...prevFiles, ...validFiles]);
            uploadFiles(validFiles);
        }
    };

    const removeFile = (index) => {
        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    const uploadFiles = async (selectedFiles) => {
        setIsLoading(true);

        try {
            const formData = new FormData();
            selectedFiles.forEach((file, index) => {
                formData.append('pdfs', file);
            });

            const response = await fetch(serverURL + '/api/upload', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.success) {
                // Merge new analysis data with existing data
                // setAnalysisData(prevData => ({
                //     topic: data.topic || prevData.topic,
                //     subTopics: [...prevData.subTopics, ...(data.subTopics || [])],
                //     summary: [prevData.summary, data.summary].filter(Boolean).join(' ')
                // }));
                
                // Store document IDs
                sessionStorage.setItem('lastEmbedIdS', JSON.stringify(data.documentIds || []));
                sessionStorage.setItem('lastDocsURLs', JSON.stringify(data.urls || []));
                
                // Update form values with new subtopics
                // if (data.subTopics) {
                //     setFormValues(data.subTopics.map(subTopic => ({ sub: subTopic })));
                // }
            } else {
                setErrors(prev => ({
                    ...prev,
                    upload: data.message || 'Error processing PDFs'
                }));
            }
        } catch (err) {
            setErrors(prev => ({
                ...prev,
                upload: 'Failed to upload and analyze PDFs'
            }));
            console.error('Upload error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        if (sessionStorage.getItem('type') !== 'free') {
            setPaidMember(true);
            setLableText('Select number of Chapters')
        }
    }, []);

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        if (formValues.length < maxSubtopics) {
            setFormValues([...formValues, { sub: "" }]);
        } else {
            showToast('You can only add 5 sub topics');
        }
    }

    let removeFormFields = () => {
        let newFormValues = [...formValues];
        newFormValues.pop();
        setFormValues(newFormValues);
    }

    const showPaidToast = async () => {
        if (!paidMember) {
            toast("For paid members only", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }

    const showToast = async (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // const subtopics = [];
        // setProcessing(true);
        // formValues.forEach(subtopic => {
        //     subtopics.push(subtopic.subtopic);
        // });
        const subtopics = formValues.map(subtopic => subtopic.sub);

        const mainTopic = document.getElementById('topic1').value;

        if (!mainTopic.trim()) {
            setProcessing(false);
            showToast('Please fill in all required fields');
            return;
        }

        if (subtopics.length === 0) {
            setProcessing(false);
            showToast('Please fill in all required fields');
            return;
        }

        const prompt = `Generate a list of Strict ${selectedValue} topics and any number sub topic for each topic for main title ${mainTopic.toLowerCase()}, everything in single line. Those ${selectedValue} topics should Strictly include these topics :- ${subtopics.join(', ').toLowerCase()} ${analysisData.summary ? (" based on this summary :- " + analysisData.summary) : ''}. Strictly Keep theory, youtube, image field empty. Generate in the form of JSON in this format {
            "${mainTopic.toLowerCase()}": [
       {
       "title": "Topic Title",
       "subtopics": [
        {
        "title": "Sub Topic Title",
        "theory": "",
        "youtube": "",
        "image": "",
        "done": false
        },
        {
        "title": "Sub Topic Title",
        "theory": "",
        "youtube": "",
        "image": "",
        "done": false
        }
       ]
       },
       {
       "title": "Topic Title",
       "subtopics": [
        {
        "title": "Sub Topic Title",
        "theory": "",
        "youtube": "",
        "image": "",
        "done": false
        },
        {
        "title": "Sub Topic Title",
        "theory": "",
        "youtube": "",
        "image": "",
        "done": false
        }
       ]
       }
      ]
      }`;

        sendPrompt(prompt, mainTopic, selectedType)

    };

    async function sendPrompt(prompt, mainTopic, selectedType) {
        setProcessing(true);
        const dataToSend = {
            prompt: prompt,
        };
        try {
            const postURL = serverURL + '/api/prompt';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.generatedText;
            const cleanedJsonString = generatedText.replace(/```json/g, '').replace(/```/g, '');
            try {
                const parsedJson = JSON.parse(cleanedJsonString);
                setProcessing(false);
                navigate('/topics', { state: { jsonData: parsedJson, mainTopic: mainTopic.toLowerCase(), type: selectedType.toLowerCase() } });
            } catch (error) {
                sendPrompt(prompt, mainTopic, selectedType)
            }

        } catch (error) {
            sendPrompt(prompt, mainTopic, selectedType)
        }
    }

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleRadioChangeType = (event) => {
        setSelectedType(event.target.value);
    };
    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={true} className="sticky top-0 z-50" />

            <div className='dark:bg-black flex-1'>
                <p className='text-center font-black text-4xl text-black dark:text-white'>Generate Course</p>
                <p className='text-center font-normal text-black py-4 dark:text-white'>
                    Type the topic on which you want to Generate course.<br />
                    Also, you can enter a list of subtopics, which are the specifics you want to learn.
                </p>
                <div className='flex-1 lg:flex justify-center pb-10 shadow-md'>
                    

                    <form onSubmit={handleSubmit} className="max-w-sm py-4 no-scrollbar lg:w-[40%]" style={{ maxWidth: '90%', paddingLeft: '5%' }}>
                        <div className='py-6'>
                            <div className='mb-6'>
                                <div className="mb-2 block">
                                    <Label className="font-bold text-black dark:text-white" htmlFor="topic1" value="Topic" />
                                </div>
                                <input onChange={e => setAnalysisData({ ...analysisData, topic: e.target.value })} className='focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none block w-full dark:bg-black dark:border-white dark:text-white' id="topic1" type="text" value={analysisData.topic !== '' ? analysisData.topic : ''} />
                            </div>
                            <div className='mb-6'>
                                <div className="mb-2 block">
                                    <Label className="font-bold text-black dark:text-white" htmlFor="subtopic" value="themes" />
                                </div>
                                {formValues.map((element, index) => (
                                    <div key={index}>
                                        <input
                                            onChange={(e) => {
                                                const updatedSubTopics = [...analysisData.subTopics];
                                                updatedSubTopics[index] = e.target.value;
                                                setAnalysisData({ ...analysisData, subTopics: updatedSubTopics });
                                            }}
                                            className='focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none block w-full dark:bg-black dark:border-white dark:text-white mb-6'
                                            id="subtopic"
                                            type="text"
                                            value={analysisData.subTopics[index] || ''}
                                        />
                                    </div>
                                ))}
                            </div>

                            <Button type="button" onClick={() => addFormFields()} className='mb-6 items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent'>Add theme</Button>

                            {formValues.length > 1 && (
                                <Button type="button" onClick={() => removeFormFields()} className='mb-6 items-center justify-center text-center border-black dark:border-white dark:bg-black dark:text-white bg-white text-black font-bold rounded-none w-full enabled:hover:bg-white enabled:focus:bg-white enabled:focus:ring-transparent dark:enabled:hover:bg-black dark:enabled:focus:bg-black dark:enabled:focus:ring-transparent'>Remove theme</Button>
                            )}

                            <Label className="font-bold text-black dark:text-white" htmlFor="nosubtopic" value={lableText} />
                            <fieldset className="flex flex-col gap-4 mt-2">
                                <div className="flex items-center gap-2 px-2 h-11 focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none w-full dark:bg-black dark:border-white dark:text-white">
                                    <Radio onChange={handleRadioChange} className='text-black border-black dark:text-white dark:border-white dark:focus:text-black focus:ring-black dark:focus:ring-white dark:focus:bg-black ' id="4" name="value" value="5" defaultChecked />
                                    <Label className='text-black dark:text-white font-bold' htmlFor="4">5</Label>
                                </div>
                                <div onClick={() => showPaidToast()} className="flex items-center gap-2 px-2 h-11 focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none w-full dark:bg-black dark:border-white dark:text-white mb-6">
                                    <Radio onChange={handleRadioChange} disabled={!paidMember} className='text-black border-black dark:text-white dark:border-white dark:focus:text-black focus:ring-black dark:focus:ring-white dark:focus:bg-black ' id="7" name="value" value="10" />
                                    <Label className='text-black dark:text-white font-bold' htmlFor="7">10</Label>
                                </div>
                            </fieldset>

                            <Label className="font-bold text-black dark:text-white" htmlFor="nosubtopic" value="Select Course Type" />
                            <fieldset className="flex flex-col gap-4 mt-2">
                                <div className="flex items-center gap-2 px-2 h-11 focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none w-full dark:bg-black dark:border-white dark:text-white">
                                    <Radio onChange={handleRadioChangeType} className='text-black border-black dark:text-white dark:border-white dark:focus:text-black focus:ring-black dark:focus:ring-white dark:focus:bg-black ' id="textcourse" name="value1" value="Text & Image Course" defaultChecked />
                                    <Label className='text-black dark:text-white font-bold' htmlFor="textcourse">Theory & Image Course</Label>
                                </div>
                                <div onClick={() => showPaidToast()} className="flex items-center gap-2 px-2 h-11 focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none w-full dark:bg-black dark:border-white dark:text-white mb-6">
                                    <Radio onChange={handleRadioChangeType} disabled={!paidMember} className='text-black border-black dark:text-white dark:border-white dark:focus:text-black focus:ring-black dark:focus:ring-white dark:focus:bg-black ' id="videocourse" name="value1" value="Video & Text Course" />
                                    <Label className='text-black dark:text-white font-bold' htmlFor="videocourse">Video & Theory Course</Label>
                                </div>
                            </fieldset>
                            {/* file upload input */}
                            <Label className="font-bold text-black dark:text-white" htmlFor="" value="Upload PDFs (Optional)" />
                            <fieldset className="flex flex-col gap-4 m-2">
                                <div className="flex items-center gap-2 px-2 h-11 focus:ring-black font-normal bg-white rounded-none w-full">
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        multiple
                                        onChange={handleFileSelect}
                                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-none file:border-0 file:text-sm file:font-semibold hover:file:bg-gray-700"
                                    />
                                    {isLoading ? (<Loader2 className="h-6 w-6 animate-spin text-gray-500" />):("")}
                                </div>
                                <div className="w-full mt-4">
                                    {files.map((file, index) => (
                                        <div key={index} className="flex items-center justify-between p-2 mb-2 bg-gray-50 rounded">
                                            <span className="text-sm truncate">{file.name}</span>
                                            <button
                                                onClick={() => removeFile(index)}
                                                className="p-1 hover:bg-gray-200 rounded"
                                            >
                                                <X className="h-4 w-4 text-gray-500" />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                            <Button isProcessing={processing} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} className='items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent' type="submit">Submit</Button>
                        </div>

                    </form>
               </div>
            </div>
        </div>
    );
};

export default Create;